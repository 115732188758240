import Portal from '../Portal'
import React, { useEffect } from 'react';
import Close from "./images/close-icon.svg";
import CloseIcon from './CloseIcon';
import { useSpring, animated } from 'react-spring';


const Modal = ({ children, isOpen, handleClose, wrapperId, isCloseIcon = true, closeIcon, width }) => {
    const modalStyles = useSpring({
        to: { opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -50 },
    })

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflowY = isOpen ? 'hidden' : 'auto'
    }, [isOpen])

    return (
        <Portal wrapperId={wrapperId}>
            {
                isOpen && <animated.div className="fixed inset-0 grid z-50 overflow-auto">
                    <animated.div className="relative w-full h-full z-60 place-items-center">
                        <animated.div className='absolute inset-0 bg-zinc-700/60' onClick={handleClose}></animated.div>
                        <animated.div style={modalStyles} className={`mx-auto mb-20 rounded-xl relative z-50 mt-[50px] sm:px-16 px-6 sm:min-w-[500px] sm:min-h-[500px] w-[90vw] sm:w-[70vw] bg-white p-10 overflow-auto`}>
                            {
                                isCloseIcon && <div className='relative mb-4 sm:mb-10'>
                                    <CloseIcon onClick={handleClose} className="ml-auto cursor-pointer w-[30px] h-[30px] sm:w-auto sm:h-auto" />
                                </div>
                            }
                            {children}
                        </animated.div>
                    </animated.div>
                </animated.div>
            }
        </Portal>
    )
}

export default Modal