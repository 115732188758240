import React from "react"

const CloseIcon = (props) => {
    return <svg {...props} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27" cy="27" r="26.5" fill="#4A4F5C" stroke="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.3125 18.7071C37.703 18.3166 37.703 17.6834 37.3125 17.2929C36.922 16.9024 36.2888 16.9024 35.8983 17.2929L27.3027 25.8885L18.7071 17.2929C18.3166 16.9024 17.6834 16.9024 17.2929 17.2929C16.9024 17.6834 16.9024 18.3166 17.2929 18.7071L25.8885 27.3027L17.2929 35.8983C16.9024 36.2888 16.9024 36.922 17.2929 37.3125C17.6834 37.703 18.3166 37.703 18.7071 37.3125L27.3027 28.7169L35.8983 37.3125C36.2888 37.703 36.922 37.703 37.3125 37.3125C37.703 36.922 37.703 36.2888 37.3125 35.8983L28.7169 27.3027L37.3125 18.7071Z" fill="white" />
    </svg>

}

export default CloseIcon