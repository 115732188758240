
import React, { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom';
import { useState } from 'react';

const Portal = ({ children, wrapperId = "react-portal-wrapper" }) => {
    const [wrapperElement, setWrapperElement] = useState(null);

    const createWrapperAndAppendToBody = (id) => {
        const wrapperElement = document.createElement('div');
        wrapperElement.setAttribute("id", id);
        wrapperElement.setAttribute("class", 'portal')
        document.body.appendChild(wrapperElement);
        return wrapperElement;
    }


    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let systemCreated = false;
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);

        return () => {
            // delete the programatically created element
            if (systemCreated && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        }
    }, [wrapperId]);

    // wrapperElement state will be null on the very first render.
    if (wrapperElement === null) return null;

    return createPortal(children, wrapperElement);
}

export default Portal